.tg-btn {
  text-decoration: none !important;
}

.daypicker {
  width: 100%;
}

.horarioCheckBox {
  padding-top: 10px;

  padding-left: 15px;
  padding-right: 10px;
  width: 100%;
  border-radius: 5%;
}

.horarioDisponible {
  border-width: 2px;
  border-style: solid;
  border-color: #d2006e;
}

.horarioNoDisponible {
  border-width: 2px;
  border-style: solid;
  border-color: red;
}

.horarioDeshabilitado {
  border-width: 2px;
  border-style: solid;
  border-color: gray;
}

.copyRightlink {
  color: white;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
}

.copyRightlink:hover {
  color: #8dbe22;
  text-decoration: none;
}

.produbancoLogo {
  position: absolute;
  top: 22vh;
  left: 46vw;
}

.area {
  height: 500px;
}

.MuiAlert-message {
  font-size: 16px !important;
}

/* .rs-steps-item-icon-wrapper {
  border-color: #8dbe22 !important;
} */

.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  background-color: #8dbe22 !important;
  border-color: #8dbe22 !important;
}

/* .rs-steps-item-icon-wrapper > .rs-steps-item-icon .rs-icon {
  color: #8dbe22 !important;
} */
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  color: #8dbe22 !important;
  border-color: #8dbe22 !important;
}

th {
  text-align: left !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
td {
  text-align: left !important;
  font-size: 14px !important;
}

.MuiTablePagination-selectLabel {
  font-size: 14px !important;
}

.MuiTablePagination-input {
  font-size: 12px !important;
}
.MuiTablePagination-displayedRows {
  font-size: 12px !important;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
.detallesLink {
  /* color: white; */
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  padding: 5px;
  color: #403f4a;
}

.detallesLink:hover {
  color: #8dbe22;
  text-decoration: none;
}

.detallesLink2 {
  color: #D2006E;
  text-decoration: none;
}

.detallesLink2:hover {
  color: #D2006E;
  text-decoration: none;
  font-size: 16px;
}

.buttonContainer .tg-btn {
  margin-top: 5px;
  margin-left: 5px;
}
.buttonRight {
  float: right;
}

@media (max-width: 960px) {
  .buttonContainer {
    text-align: center;
  }
  .buttonContainer .tg-btn {
    margin-top: 15px;
    margin-left: 0;
  }
  .buttonRight {
    float: left;
  }
}

.payButton {
  min-width: 280px;
  background: #f9f9f9;
  color: black;
}
.payButton:hover {
  background: black;
  color: #f9f9f9;
}

.box {
  display: flex;
}
