.each-fade {
  display: flex;
  width: 100%;
}

.each-fade > div {
  width: 100%;
}

.each-fade > div img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.each-fade .content {
  width: 25%;

  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #403f4a;
}

.each-fade .content div h6 {
  color: white;
}

.each-fade .content div p {
  color: white;
}

@media (max-width: 600px) {
  .each-fade > div img {
    height: 250px;
  }
  .each-fade .content {
    width: 100%;
  }
  .each-fade .content div h6 {
    font-size: 12px;
  }

  .each-fade .content div p {
    font-size: 10px;
  }
}
