.container .input {
  border: 2;
  outline: none;
  color: #8b7d77;
}

.search_wrap {
  max-width: 400px;
  text-align: center;
}

.search_wrap .search_box {
  position: relative;
  max-width: 400px;
  height: 60px;
}

.search_wrap .search_box .input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 18px;
  color: #8b7d77;
}

.search_wrap .search_box .btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  background: #393843;
  z-index: 1;
  cursor: pointer;
}

.search_wrap .search_box .btn:hover {
  background: #393843;
}

.search_wrap .search_box .btn.btn_common .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
}

.search_wrap.search_wrap_1 .search_box .btn {
  right: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.search_wrap.search_wrap_1 .search_box .input,
.search_wrap.search_wrap_3 .search_box .input {
  padding-right: 80px;
  color: #8b7d77;
}

@media (min-width: 600px) {
  .search_wrap {
    margin: 10px 100px;
  }
}

@media (min-width: 976.89px) {
  .search_wrap {
    margin: 10px 200px;
  }
}

@media (min-width: 1200px) {
  .search_wrap {
    margin: 10px 300px;
  }
}
