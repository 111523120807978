.MuiStepLabel-labelContainer span {
  font-size: 16px;
}

/* .MuiStepLabel-iconContainer svg {
  font-size: 30px !important;
} */

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #d2006e !important;
  font-size: 30px !important;
  font-weight: 700;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  /* color: black !important; */
  font-size: 30px !important;
  font-weight: 700;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #d2006e !important;
  font-size: 25px !important;
}

.css-rh92k-MuiPaper-root-MuiMobileStepper-root {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #8dbe22 !important;
  /*   color: rgba(0, 0, 0, 0.87); */
  padding: 0px !important ;
  margin-left: 15px;
}
